import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_image_aaba817e from 'nuxt_plugin_image_aaba817e' // Source: ./image.js (mode: 'all')
import nuxt_plugin_recaptcha_01dc8496 from 'nuxt_plugin_recaptcha_01dc8496' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_nuxtvkpixelmodule1ac30c16_75d59a98 from 'nuxt_plugin_nuxtvkpixelmodule1ac30c16_75d59a98' // Source: ./nuxt-vk-pixel.module.1ac30c16.js (mode: 'client')
import nuxt_plugin_yandexmetrikaplugin27716ce3_91209844 from 'nuxt_plugin_yandexmetrikaplugin27716ce3_91209844' // Source: ./yandex-metrika.plugin.27716ce3.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_1254e4e2 from 'nuxt_plugin_nuxtuseragent_1254e4e2' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_deviceplugin_7007b3d1 from 'nuxt_plugin_deviceplugin_7007b3d1' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_70cffde4 from 'nuxt_plugin_nuxtsvgsprite_70cffde4' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_localization_3205610f from 'nuxt_plugin_localization_3205610f' // Source: ../plugins/localization (mode: 'all')
import nuxt_plugin_debug_352396c6 from 'nuxt_plugin_debug_352396c6' // Source: ../plugins/debug (mode: 'client')
import nuxt_plugin_notifications_36de4932 from 'nuxt_plugin_notifications_36de4932' // Source: ../plugins/notifications (mode: 'client')
import nuxt_plugin_vuetippy_b7941d2e from 'nuxt_plugin_vuetippy_b7941d2e' // Source: ../plugins/vue-tippy (mode: 'all')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_logger_52e3bef4 from 'nuxt_plugin_logger_52e3bef4' // Source: ../plugins/logger (mode: 'client')
import nuxt_plugin_ui_d91f5772 from 'nuxt_plugin_ui_d91f5772' // Source: ../plugins/services/core/ui (mode: 'all')
import nuxt_plugin_axios_c863f43a from 'nuxt_plugin_axios_c863f43a' // Source: ../plugins/services/core/axios (mode: 'all')
import nuxt_plugin_hub_4acbf4bc from 'nuxt_plugin_hub_4acbf4bc' // Source: ../plugins/services/core/hub (mode: 'all')
import nuxt_plugin_persistedstateclient_4935761f from 'nuxt_plugin_persistedstateclient_4935761f' // Source: ../plugins/persisted-state.client.js (mode: 'client')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_index_5a670d87 from 'nuxt_plugin_index_5a670d87' // Source: ../plugins/services/dispatcher/index (mode: 'client')
import nuxt_plugin_doctor_8319205e from 'nuxt_plugin_doctor_8319205e' // Source: ../plugins/services/dispatcher/doctor.service (mode: 'client')
import nuxt_plugin_clinic_581f1a9a from 'nuxt_plugin_clinic_581f1a9a' // Source: ../plugins/services/dispatcher/clinic.service (mode: 'client')
import nuxt_plugin_consultation_6be8ea0a from 'nuxt_plugin_consultation_6be8ea0a' // Source: ../plugins/services/dispatcher/consultation.service (mode: 'client')
import nuxt_plugin_files_ab78e70c from 'nuxt_plugin_files_ab78e70c' // Source: ../plugins/services/files (mode: 'all')
import nuxt_plugin_chat_aab41616 from 'nuxt_plugin_chat_aab41616' // Source: ../plugins/services/chat (mode: 'all')
import nuxt_plugin_labs_aaac1bba from 'nuxt_plugin_labs_aaac1bba' // Source: ../plugins/services/labs (mode: 'all')
import nuxt_plugin_directions_703de0b1 from 'nuxt_plugin_directions_703de0b1' // Source: ../plugins/services/directions (mode: 'all')
import nuxt_plugin_call_aab4480a from 'nuxt_plugin_call_aab4480a' // Source: ../plugins/services/call (mode: 'client')
import nuxt_plugin_status_96198362 from 'nuxt_plugin_status_96198362' // Source: ../plugins/services/status (mode: 'all')
import nuxt_plugin_promotion_ab79d5b4 from 'nuxt_plugin_promotion_ab79d5b4' // Source: ../plugins/services/promotion (mode: 'client')
import nuxt_plugin_auth_aab58176 from 'nuxt_plugin_auth_aab58176' // Source: ../plugins/services/auth (mode: 'all')
import nuxt_plugin_remotemonitoring_9faf949c from 'nuxt_plugin_remotemonitoring_9faf949c' // Source: ../plugins/services/remote-monitoring (mode: 'client')
import nuxt_plugin_stories_2b8e84d4 from 'nuxt_plugin_stories_2b8e84d4' // Source: ../plugins/services/stories (mode: 'all')
import nuxt_plugin_analitics_7289f60a from 'nuxt_plugin_analitics_7289f60a' // Source: ../plugins/services/analitics (mode: 'client')
import nuxt_plugin_mailMetrika_57095c37 from 'nuxt_plugin_mailMetrika_57095c37' // Source: ../plugins/services/mailMetrika (mode: 'client')
import nuxt_plugin_services_48b85b7b from 'nuxt_plugin_services_48b85b7b' // Source: ../plugins/services/services (mode: 'all')
import nuxt_plugin_medicalquestionnaire_46d0b36a from 'nuxt_plugin_medicalquestionnaire_46d0b36a' // Source: ../plugins/services/medical-questionnaire (mode: 'all')
import nuxt_plugin_supportchat_7eb1999a from 'nuxt_plugin_supportchat_7eb1999a' // Source: ../plugins/services/support-chat (mode: 'all')
import nuxt_plugin_referralanalysis_bcfe3ee2 from 'nuxt_plugin_referralanalysis_bcfe3ee2' // Source: ../plugins/services/referral-analysis (mode: 'all')
import nuxt_plugin_referralconsultations_b0232d92 from 'nuxt_plugin_referralconsultations_b0232d92' // Source: ../plugins/services/referral-consultations (mode: 'all')
import nuxt_plugin_doctors_70ea1f12 from 'nuxt_plugin_doctors_70ea1f12' // Source: ../plugins/services/doctors (mode: 'all')
import nuxt_plugin_notifications_1c26b3eb from 'nuxt_plugin_notifications_1c26b3eb' // Source: ../plugins/services/notifications (mode: 'client')
import nuxt_plugin_momsschool_2b9e5ddc from 'nuxt_plugin_momsschool_2b9e5ddc' // Source: ../plugins/services/moms-school (mode: 'all')
import nuxt_plugin_payment_2ba48f49 from 'nuxt_plugin_payment_2ba48f49' // Source: ../plugins/services/payment (mode: 'all')
import nuxt_plugin_seo_370d99c0 from 'nuxt_plugin_seo_370d99c0' // Source: ../plugins/services/seo (mode: 'all')
import nuxt_plugin_sentry_40a2c6d3 from 'nuxt_plugin_sentry_40a2c6d3' // Source: ../plugins/sentry (mode: 'all')
import nuxt_plugin_amplitude_27414de2 from 'nuxt_plugin_amplitude_27414de2' // Source: ../plugins/amplitude (mode: 'client')
import nuxt_plugin_varioqub_aebaa2f2 from 'nuxt_plugin_varioqub_aebaa2f2' // Source: ../plugins/varioqub (mode: 'client')
import nuxt_plugin_datefns_7ae82800 from 'nuxt_plugin_datefns_7ae82800' // Source: ../plugins/date-fns (mode: 'all')
import nuxt_plugin_vuetextmask_53bcaade from 'nuxt_plugin_vuetextmask_53bcaade' // Source: ../plugins/vue-text-mask (mode: 'all')
import nuxt_plugin_libnuxtclientinitpluginclient47e59c91_5a391cbc from 'nuxt_plugin_libnuxtclientinitpluginclient47e59c91_5a391cbc' // Source: ./lib.nuxt-client-init.plugin.client.47e59c91.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"patient","htmlAttrs":{"lang":"ru"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"script":[{"src":"https:\u002F\u002Fvk.com\u002Fjs\u002Fapi\u002Fopenapi.js?169","async":true}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.svg"},{"rel":"icon","type":"image\u002Fsvg+xml","href":"\u002Ffavicon.svg"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon.png"},{"href":"https:\u002F\u002Fmc.yandex.ru\u002Fmetrika\u002Ftag.js","rel":"preload","as":"script"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_image_aaba817e === 'function') {
    await nuxt_plugin_image_aaba817e(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_01dc8496 === 'function') {
    await nuxt_plugin_recaptcha_01dc8496(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvkpixelmodule1ac30c16_75d59a98 === 'function') {
    await nuxt_plugin_nuxtvkpixelmodule1ac30c16_75d59a98(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_yandexmetrikaplugin27716ce3_91209844 === 'function') {
    await nuxt_plugin_yandexmetrikaplugin27716ce3_91209844(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_1254e4e2 === 'function') {
    await nuxt_plugin_nuxtuseragent_1254e4e2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_7007b3d1 === 'function') {
    await nuxt_plugin_deviceplugin_7007b3d1(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_70cffde4 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_70cffde4(app.context, inject)
  }

  if (typeof nuxt_plugin_localization_3205610f === 'function') {
    await nuxt_plugin_localization_3205610f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_debug_352396c6 === 'function') {
    await nuxt_plugin_debug_352396c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_36de4932 === 'function') {
    await nuxt_plugin_notifications_36de4932(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetippy_b7941d2e === 'function') {
    await nuxt_plugin_vuetippy_b7941d2e(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_logger_52e3bef4 === 'function') {
    await nuxt_plugin_logger_52e3bef4(app.context, inject)
  }

  if (typeof nuxt_plugin_ui_d91f5772 === 'function') {
    await nuxt_plugin_ui_d91f5772(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_c863f43a === 'function') {
    await nuxt_plugin_axios_c863f43a(app.context, inject)
  }

  if (typeof nuxt_plugin_hub_4acbf4bc === 'function') {
    await nuxt_plugin_hub_4acbf4bc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_persistedstateclient_4935761f === 'function') {
    await nuxt_plugin_persistedstateclient_4935761f(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_5a670d87 === 'function') {
    await nuxt_plugin_index_5a670d87(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_doctor_8319205e === 'function') {
    await nuxt_plugin_doctor_8319205e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clinic_581f1a9a === 'function') {
    await nuxt_plugin_clinic_581f1a9a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_consultation_6be8ea0a === 'function') {
    await nuxt_plugin_consultation_6be8ea0a(app.context, inject)
  }

  if (typeof nuxt_plugin_files_ab78e70c === 'function') {
    await nuxt_plugin_files_ab78e70c(app.context, inject)
  }

  if (typeof nuxt_plugin_chat_aab41616 === 'function') {
    await nuxt_plugin_chat_aab41616(app.context, inject)
  }

  if (typeof nuxt_plugin_labs_aaac1bba === 'function') {
    await nuxt_plugin_labs_aaac1bba(app.context, inject)
  }

  if (typeof nuxt_plugin_directions_703de0b1 === 'function') {
    await nuxt_plugin_directions_703de0b1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_call_aab4480a === 'function') {
    await nuxt_plugin_call_aab4480a(app.context, inject)
  }

  if (typeof nuxt_plugin_status_96198362 === 'function') {
    await nuxt_plugin_status_96198362(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_promotion_ab79d5b4 === 'function') {
    await nuxt_plugin_promotion_ab79d5b4(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_aab58176 === 'function') {
    await nuxt_plugin_auth_aab58176(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_remotemonitoring_9faf949c === 'function') {
    await nuxt_plugin_remotemonitoring_9faf949c(app.context, inject)
  }

  if (typeof nuxt_plugin_stories_2b8e84d4 === 'function') {
    await nuxt_plugin_stories_2b8e84d4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analitics_7289f60a === 'function') {
    await nuxt_plugin_analitics_7289f60a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mailMetrika_57095c37 === 'function') {
    await nuxt_plugin_mailMetrika_57095c37(app.context, inject)
  }

  if (typeof nuxt_plugin_services_48b85b7b === 'function') {
    await nuxt_plugin_services_48b85b7b(app.context, inject)
  }

  if (typeof nuxt_plugin_medicalquestionnaire_46d0b36a === 'function') {
    await nuxt_plugin_medicalquestionnaire_46d0b36a(app.context, inject)
  }

  if (typeof nuxt_plugin_supportchat_7eb1999a === 'function') {
    await nuxt_plugin_supportchat_7eb1999a(app.context, inject)
  }

  if (typeof nuxt_plugin_referralanalysis_bcfe3ee2 === 'function') {
    await nuxt_plugin_referralanalysis_bcfe3ee2(app.context, inject)
  }

  if (typeof nuxt_plugin_referralconsultations_b0232d92 === 'function') {
    await nuxt_plugin_referralconsultations_b0232d92(app.context, inject)
  }

  if (typeof nuxt_plugin_doctors_70ea1f12 === 'function') {
    await nuxt_plugin_doctors_70ea1f12(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_1c26b3eb === 'function') {
    await nuxt_plugin_notifications_1c26b3eb(app.context, inject)
  }

  if (typeof nuxt_plugin_momsschool_2b9e5ddc === 'function') {
    await nuxt_plugin_momsschool_2b9e5ddc(app.context, inject)
  }

  if (typeof nuxt_plugin_payment_2ba48f49 === 'function') {
    await nuxt_plugin_payment_2ba48f49(app.context, inject)
  }

  if (typeof nuxt_plugin_seo_370d99c0 === 'function') {
    await nuxt_plugin_seo_370d99c0(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_40a2c6d3 === 'function') {
    await nuxt_plugin_sentry_40a2c6d3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_amplitude_27414de2 === 'function') {
    await nuxt_plugin_amplitude_27414de2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_varioqub_aebaa2f2 === 'function') {
    await nuxt_plugin_varioqub_aebaa2f2(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_7ae82800 === 'function') {
    await nuxt_plugin_datefns_7ae82800(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetextmask_53bcaade === 'function') {
    await nuxt_plugin_vuetextmask_53bcaade(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclient47e59c91_5a391cbc === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclient47e59c91_5a391cbc(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
