import { Api } from '~/api';

class PaymentApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API;
    super(client, baseUrl);
  }

  pay(payload) {
    return this.post('/payment/plantao/pay', payload);
  }

  info(payload) {
    return this.get(`/payment/plantao/info?orderId=${payload}`);
  }
}

export default PaymentApi;
